body
{
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    background:#000;
    font: 14px/21px Monaco,sans-serif;
    color:#999;
    font-smooth:antialiased;
    text-size-adjust:100%;
}

.scene
{
    width: 100vw;
    height:100vh;
    perspective: 600;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scene svg
{
    width: 480px;
    height: 480px;  
}